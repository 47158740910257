import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './Header.scss';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    mobileMenuOpen ? setMobileMenuOpen(false) : setMobileMenuOpen(true);
    document.body.classList.toggle("no-scroll");
  }

  const handleNavClick = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
      document.body.classList.toggle("no-scroll");
    }
  }

  return (
    <header 
      className={`content-wrapper ${mobileMenuOpen && 'open'}`}
    >
      <NavLink 
        to={'/'}
        className='logo'
      >
        S & T
      </NavLink>
      <nav
        className={`header-nav ${mobileMenuOpen && 'open'}`}
        onClick={handleNavClick}
      >
        <NavLink 
          to={'/'}
          className={({ isActive }) => isActive ? 'active-route' : ''}
        >
          Hem
        </NavLink>
        <NavLink 
          to={'/boende'}
          className={({ isActive }) => isActive ? 'active-route' : ''}
        >
          Boende
        </NavLink>
        <NavLink 
          to={'/bra-att-veta'}
          className={({ isActive }) => isActive ? 'active-route' : ''}
        >
          Bra att veta
        </NavLink>
        <a 
          className="button"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfsCiBIRRgMnYnxLUFb8I3hvAzXV9k9ejtZrewh2-CYI3cCXA/viewform?usp=sf_link"
          target="_blank"
        >
          OSA
        </a>
      </nav>
      <div 
        className={`hamburger-outer ${mobileMenuOpen && 'open'}`}
        onClick={handleHamburgerClick}
      >
        <span className='lines'>
          <span></span>
        </span>
      </div>
    </header>
  )
}

export default Header;