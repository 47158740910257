import React from "react";
import heroImg from '../media/hero-image.jpg';
import weddingPartyImg from '../media/party.png';
import weddingBrunchImg from '../media/brunch.png';
import './Home.scss';

export default function Home() {

  return (
    <>
      <section className="content-wrapper">
        <div className="hero-outer">
          <div className="hero-col-left">
            <img 
              src={heroImg}
            />
          </div>
          <div className="hero-col-right">
            <div className="hero-text-wrapper">
              <h6>Skärgårdsbröllop i Hammerska ladan </h6>
              <h1>Sanna & Tobbe</h1>
              <h4>28 juni, 2025</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="wedding-day-1">
        <div className="content-wrapper">
          <div className="wedding-day-outer">
            <div className="wedding-day-col-s">
              <img 
                src={weddingPartyImg}
              />
            </div>
            <div className="wedding-day-col-l wedding-day-text-wrapper">
              <h2>Lördag <br /> 28 juni, 2025</h2>
              <h4>Vigsel: 14:00</h4>
              <h4>Middag & Fest: 18:00 - 02:00</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="wedding-day-2">
        <div className="content-wrapper">
          <div className="wedding-day-outer">
            <div className="wedding-day-col-l wedding-day-text-wrapper">
              <h2>Söndag <br /> 29 juni, 2025</h2>
              <h4>Brunch: 11:00</h4>
            </div>
            <div className="wedding-day-col-s">
              <img 
                src={weddingBrunchImg}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="light-background">
        <div className="content-wrapper">
          <div className="cta-outer">
            <h4>Hör av dig så att vi vet att du kommer senast 1 januari, 2025</h4>
            <a 
              className="button"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfsCiBIRRgMnYnxLUFb8I3hvAzXV9k9ejtZrewh2-CYI3cCXA/viewform?usp=sf_link"
              target="_blank"
            >
              Klart jag kommer!
            </a>
          </div>
        </div>
      </section>

      <section className="light-background">
        <div className="content-wrapper">
          <div className="maps-outer">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2015.1365291314187!2d18.91135027713444!3d59.66401757477811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46f55d720617f55f%3A0x9aad9ae27a6cec35!2sHammerska%20ladan!5e0!3m2!1sen!2sus!4v1727774388782!5m2!1sen!2sus" 
              width="100%" 
              height="300" 
              style={{border: '0px'}}
              loading="lazy"
              >
            </iframe>
          </div>
        </div>
      </section>
    </>
  )
}
