import React from "react";
import './PageHero.scss';

const PageHero = ({img, text}) => {

  return (
    <>
      <div className="page-hero-outer">
        <img 
          src={img}
        />
        <h1 className="content-wrapper">
          {text}
        </h1>
      </div>
    </>
  )
}

export default PageHero;