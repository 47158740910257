import { RouterProvider, createBrowserRouter, Route, createRoutesFromElements, Routes } from 'react-router-dom';
import Root from './components/Root.tsx';
import Home from './components/Home.tsx';
import Faq from './components/Faq.tsx';
import Accomodation from './components/Accomodation.tsx';

const router = createBrowserRouter( createRoutesFromElements(
  <Route path="/" element={ <Root/> }>
    <Route index element={ <Home />} />
    <Route path="boende" element={ <Accomodation />} />
    <Route path="bra-att-veta" element={ <Faq />} />
  </Route>
  )
)

function App() {
  return (
    <>
      <RouterProvider router={router}/>
    </>
  );
}

export default App;
