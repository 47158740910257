import React from "react";
import './Footer.scss';

const Footer = () => {

  return (
    <footer className="content-wrapper">
      <div className="footer-text">
        <h6>Sanna ❤️ Tobbe</h6>
      </div>
    </footer>
  )
}

export default Footer;