import React from "react";
import './HotelOption.scss';

interface HotelOptionProps {
  hotelName: string;
  img: string; 
  link?: string;
  description?: string;
  price?: string;
  linkText?: string; 
  linkDescription?: string;
}

const HotelOption = ({hotelName, img, link, description, price, linkText, linkDescription}: HotelOptionProps) => {

  return (
    <div className="hotel-option-outer">
      <h4>{hotelName}</h4>
      <img 
        src={img} 
      />
      <div className="hotel-option-text">
        {description && 
          <p>{description}</p>
        }
        {price && 
          <p>Pris: {price}</p>
        }
        {link &&
          <p>
            {linkDescription}
            <a 
              href={link} 
              target="_blank"
            >
              {linkText}
            </a>
          </p>
        }
      </div>
    </div>
  )
}

export default HotelOption;