import React from "react";
import img from '../media/collage.png';
import PageHero from "./PageHero.tsx";
import './Faq.scss';
import FaqCard from "./FaqCard.tsx";

const Faq = () => {

  return (
    <>
      <PageHero 
        img={img}
        text={'Bra att veta'}
      />
      <section className="light-background">
        <div className="content-wrapper">
          <div className="faq-outer">
            <FaqCard
              question='Klädsel'
            >
              <p>
                Sommarfint! Det du tycker är fint, tycker vi är fint. 
              </p>
              <p>
                Förslagsvis: Kostym för herrar och klänning/byxdress eller dylikt för kvinnor.
              </p>
            </FaqCard>
            <FaqCard
              question='Får jag hålla tal?'
            >
              <p>
                Ja! Självklart får du det, viktigt att ni/du hör av er innan till våra toastmasters Viktor Bäckman och Sebastian Eddin.
              </p>
              <p>
                Anmälan för tal senast den <span className="stay-together">2024-06-01</span> via: 
                <a href="mailto:toastmaster@sannaochtobbe.se">
                  toastmaster@sannaochtobbe.se
                </a>
              </p>
            </FaqCard>
            <FaqCard
              question='Barn'
            >
              <p>
                Alla är hjärtligt välkomna till vigseln men under middag och fest så ber vi er lämna era småttingar hemma.
              </p>
            </FaqCard>
            <FaqCard
              question='Presenter'
            >
              <p>
                Present är ju såklart trevligt! Vår största önskan är att ni kommer med ett gott humör och förgyller vår kväll.
              </p>
              <p>
                Men om ni skulle vilja uttrycka er kärlek via en gåva så uppskattar vi ett bidrag till vår bröllopsresa alternativt presentkort på IKEA.
              </p>
              <p>
                För att lämna bidrag till bröllopsresa kan ni inför bröllopet Swisha till brudgummens mor (Ann Netterwall). 
                Hon kommer efter bröllopet presentera en lista med alla personer som skickat men utan att ange några belopp per person.
              </p>
              <p>
                Brudgummens mor (Ann Netterwall): 0708 16 71 39. <br />
                Skriv “Bröllop” i meddelande.
              </p>
            </FaqCard>
            <FaqCard
              question='Matpreferenser'
            >
              <p>
                I anmälningsformuläret kan ni ange matpreferenser.
              </p>
            </FaqCard>
            <FaqCard
              question='Kontaktuppgifter'
            >
              <p>
                Brudgummen: Tobias Netterwall 0762097479 <br />
                Bruden: Sanna Åkesson 0762300116
              </p>
              <p>
                I första hand maila till: 
                <a href="mailto:master@sannaochtobbe.se">master@sannaochtobbe.se</a>
              </p>
              <p>
                Toastmasters: Viktor Bäckman och Sebastian Eddin <br />
                Maila till:
                <a href="mailto:toastmaster@sannaochtobbe.se">toastmaster@sannaochtobbe.se</a>
              </p>
            </FaqCard>
          </div>
        </div>
      </section>
    </>
  )
}

export default Faq;