import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header.tsx";
import Footer from "./Footer.tsx";

const Root = () => {
  return (
    <>
      <Header/>
        <main>
          <Outlet/>
        </main>
      <Footer/>
    </>
  )
}

export default Root;