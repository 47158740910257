import React, { useState } from "react";
import chevronDown from '../media/chevron-down.svg';
import {ReactComponent as ChevronDown} from '../media/chevron-down.svg';
import './FaqCard.scss';

const FaqCard = ({ question, children }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div 
      className={`${isOpen ? 'open' : ''} faq-card-outer`}
      onClick={() => setOpen(!isOpen)}
      role="button"
    >
      <div className="faq-card-content">
        <p>{question}</p>
        <ChevronDown />
      </div>
      <div className="faq-card-answer">
        {children}
      </div>
    </div>
  )
}

export default FaqCard;